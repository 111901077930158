import {
  CancelPickupRequestInterface,
  ExportPickupRequestInterface,
  ForceCancelCRRQUERequestInterface,
  ForceCRRSRCRequestInterface,
  ManualAssigneeRequestInterface,
  MarkAsDoneRequestInterface,
  PickupDedicatedCRROTWRequestInterface,
  PickupForceCRRCNCRequestInterface,
  UpdateIsContactedCRROTWRequestInterface,
  UpdateIsContactedCRRQUERequestInterface,
} from '@/data/payload/contracts/PickupRequest'

export class ExportPickupApiRequest implements ExportPickupRequestInterface {
  private statusId: string
  private email: string

  constructor(statusId: string, email: string) {
    this.statusId = statusId
    this.email = email
  }

  public toPayload(): { status_id: string; email: string } {
    return {
      status_id: this.statusId,
      email: this.email,
    }
  }
}

export class ManualAssigneeApiRequest
  implements ManualAssigneeRequestInterface {
  courierId?: number
  ids?: string[]
  manualAssignDate?: string
  timeZone?: string
  plateNo?: string

  constructor(fields?: Partial<ManualAssigneeApiRequest>) {
    Object.assign(this, fields)
  }

  public toPayload(): {
    courier_id: number,
    ids: string[],
    manual_assign_date?: string,
    time_zone?: string
    plat_no?: string
  } {
    return {
      courier_id: <number>this.courierId,
      ids: <string[]>this.ids,
      manual_assign_date: this.manualAssignDate,
      time_zone: this.timeZone,
      plat_no: this.plateNo
    }
  }
}

export class MarkAsDoneApiRequest implements MarkAsDoneRequestInterface {
  private groupId: string | undefined
  private shipmentId: string | undefined
  private courierId: number
  private quantity: number
  private hubPersonName: string
  private personName: string
  private relationId?: string

  constructor(
    groupId: string | undefined,
    shipmentId: string | undefined,
    courierId: number,
    quantity: number,
    hubPersonName: string,
    personName: string,
    relationId?: string
  ) {
    this.groupId = groupId
    this.shipmentId = shipmentId
    this.courierId = courierId
    this.quantity = quantity
    this.hubPersonName = hubPersonName
    this.personName = personName
    this.relationId = relationId
  }

  public toPayload(): {
    group_id: string | undefined
    shipment_id: string | undefined
    courier_id: number
    quantity: number
    hub_person_name: string
    person_name: string
    relation_id?: string
  } {
    return {
      group_id: this.groupId,
      shipment_id: this.shipmentId,
      courier_id: this.courierId,
      quantity: this.quantity,
      hub_person_name: this.hubPersonName,
      person_name: this.personName,
      relation_id: this.relationId,
    }
  }
}

export class CancelPickupApiRequest implements CancelPickupRequestInterface {
  private shipmentId: string
  private reasonId: string
  private note: string

  constructor(shipmentId: string, reasonId: string, note: string) {
    this.shipmentId = shipmentId
    this.reasonId = reasonId
    this.note = note
  }

  public toPayload() {
    return {
      shipment_id: this.shipmentId,
      reason_id: this.reasonId,
      note: this.note,
    }
  }
}

export class PickupDedicatedCRROTWRequest implements PickupDedicatedCRROTWRequestInterface {
  private taskId: number
  private shipmentId: string
  private actorId: number
  private email: string
  private from: string

  constructor(
    taskId: number,
    shipmentId: string,
    actorId: number,
    email: string,
    from: string
  ) {
    this.taskId = taskId
    this.shipmentId = shipmentId
    this.actorId = actorId
    this.email = email
    this.from = from
  }

  public toPayload(): {
    task_id: number;
    shipment_id: string;
    actor_id: number;
    email: string;
    from: string;
  }{
    return {
      task_id: this.taskId,
      shipment_id: this.shipmentId,
      actor_id: this.actorId,
      email: this.email,
      from: this.from
    }
  }
}

export class PickupForceCRRCNCRequest implements PickupForceCRRCNCRequestInterface {
  private shipmentId: string
  private courierId: number
  private reason: string

  constructor(
    shipmentId: string,
    courierId: number,
    reason: string
  ) {
    this.shipmentId = shipmentId
    this.courierId = courierId
    this.reason = reason
  }

  toPayload(): { shipment_id: string; courier_id: number; reason: string; } {
    return {
      courier_id: this.courierId,
      shipment_id: this.shipmentId,
      reason: this.reason
    }
  }
}

export class UpdateIsContactedCRROTWRequest implements UpdateIsContactedCRROTWRequestInterface {
  private status: number

  constructor(
    status: number,
  ) {
    this.status = status
  }

  public toPayload(): {
    status: number;
  }{
    return {
      status: this.status,
    }
  }
}

export class ForceCRRSRCRequest implements ForceCRRSRCRequestInterface {
  private shipmentId: string

  constructor(
    shipmentId: string,
  ) {
    this.shipmentId = shipmentId
  }

  toPayload(): { shipment_id: string; } {
    return {
      shipment_id: this.shipmentId
    }
  }
}

export class ForceCancelCRRQUERequest implements ForceCancelCRRQUERequestInterface {
  private shipmentId: string
  private note: string

  constructor(shipmentId: string, note: string) {
    this.shipmentId = shipmentId
    this.note = note
  }

  toPayload(): string {
    const payload = {
      shipment_id: this.shipmentId,
      note: this.note
    }

    return JSON.stringify(payload)
  }
}

export class UpdateIsContactedCRRQUERequest implements UpdateIsContactedCRRQUERequestInterface {
  private isContacted: boolean

  constructor(isContacted: boolean) {
    this.isContacted = isContacted
  }

  toPayload(): string {
    const payload = {
      is_contacted: this.isContacted
    }

    return JSON.stringify(payload)
  }
}
