import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { PickupPresenter } from '@/app/ui/presenters/PickupPresenter'
import {
  NearbyCourier,
  Pickup,
  PickupDetail,
  PickupDetailSnapshot,
  PickupForceDetail,
  PickupSummary,
  Pickups,
  PickupAdditionalTime,
  PickupAdditionalTimes,
  PickupCRROTWs,
  PickupCRRQUEs,
  PickupCorporate,
  PickupCorporates,
  PickupCorporateSummary,
} from '@/domain/entities/Pickup'
import dayjs from 'dayjs'
import {
  CancelPickupApiRequest,
  ExportPickupApiRequest,
  ForceCancelCRRQUERequest,
  ForceCRRSRCRequest,
  ManualAssigneeApiRequest,
  MarkAsDoneApiRequest,
  PickupDedicatedCRROTWRequest,
  PickupForceCRRCNCRequest,
  UpdateIsContactedCRROTWRequest,
  UpdateIsContactedCRRQUERequest,
} from '@/data/payload/api/PickupRequest'
import { PODTrackerPresenter } from '../presenters/PODTrackerPresenter'
import { PODTrackerDrivers } from '@/domain/entities/PODTracker'
import { ManageCourierPresenter } from '../presenters/ManageCourierPresenter'
import { Pagination } from '@/domain/entities/Pagination'

export interface DropdownValue {
  value: number
  label: string
}

interface MarkAsDoneForm {
  statusId: string | string[]
  groupId: string | undefined
  shipmentId: string | undefined
  courierId: number
  quantity: number
  hubPersonName: string
  personName: string
  relationId?: string
}

export interface PickupState {
  isLoading: boolean
  isExportLoading: boolean
  isExportCRRNFDLoading: boolean
  isExportCRROTWLoading: boolean
  isExportCRRQUELoading: boolean
  isCourierLoading: boolean
  isexportPickupListLoading: boolean
  isSuccessForce: boolean
  pickupData: Pickup[]
  pickupDetail: PickupDetail
  pickupDetails: PickupDetail[]
  pickupSnapshotDetail: PickupDetailSnapshot[]
  pickupSummary: PickupSummary
  nearbyCourierData: NearbyCourier[]
  courierData: DropdownValue[]
  latestSync: string | null
  pickupBulkData: PickupBulkDataState | null
  paginationData: Pagination
  requestManualAssign: string
  pickupAdditionalTimeData: PickupAdditionalTime[]
  pickupCRROTWData: PickupCRROTWs
  pickupCRRQUEData: PickupCRRQUEs
  isSuccessForceCRRCNC: boolean
  isFailedForceCRRCNC: boolean
  isForceCRRSRCSuccess: boolean
  isLoadingForceCRRSRC: boolean
  isSuccessForceCancelCRRQUE: boolean
  isFailedForceCancelCRRQUE: boolean
  isLoadingExportManualAssignLog: boolean
  isSuccessExportManualAssignLog: boolean
  pickupCorporateData: PickupCorporate[]
  pickupCorporateSummary: PickupCorporateSummary
}

export interface PickupBulkDataState {
  bookingIds: string
  courier: DropdownValue
  manualAssignDate?: Date
  manualAssignHour?: number
  manualAssignMinute?: number
  isScheduleManualAssign?: boolean
  plateNo?: string
}

@Module({ namespaced: true, store, name: 'pickup', dynamic: true })
class PickupController extends VuexModule implements PickupState {
  private presenter: PickupPresenter = container.resolve(PickupPresenter)
  private podTrackerPresenter: PODTrackerPresenter = container.resolve(
    PODTrackerPresenter
  )
  private manageCourierPresenter: ManageCourierPresenter = container.resolve(
    ManageCourierPresenter
  )
  public isLoading = false
  public isExportLoading = false
  public isExportCRRNFDLoading = false
  public isExportCRROTWLoading = false
  public isExportCRRQUELoading = false
  public isCourierLoading = false
  public isexportPickupListLoading = false
  public isSuccessForce = false
  public pickupData: Pickup[] = []
  public pickupDetail: PickupDetail = new PickupDetail()
  public pickupDetails: PickupDetail[] = [new PickupDetail()]
  public pickupDetailForce: PickupForceDetail = new PickupForceDetail()
  public pickupSnapshotDetail: PickupDetailSnapshot[] = []
  public pickupSummary: PickupSummary = new PickupSummary()
  public courierData: DropdownValue[] = []
  public nearbyCourierData: NearbyCourier[] = []
  public latestSync: string | null = null
  public pickupBulkData: PickupBulkDataState | null = null
  public paginationData = new Pagination()
  public requestManualAssign = ''
  public pickupAdditionalTimeData: PickupAdditionalTime[] = []
  public pickupCRROTWData: PickupCRROTWs = new PickupCRROTWs()
  public pickupCRRQUEData: PickupCRRQUEs = new PickupCRRQUEs()
  public isSuccessForceCRRCNC = false
  public isFailedForceCRRCNC = false
  public isForceCRRSRCSuccess = false
  public isLoadingForceCRRSRC = false
  public isSuccessForceCancelCRRQUE = false
  public isFailedForceCancelCRRQUE = false
  public isLoadingExportManualAssignLog = false
  public isSuccessExportManualAssignLog = false
  public pickupCorporateData: PickupCorporate[] = []
  public pickupCorporateSummary: PickupCorporateSummary = new PickupCorporateSummary()

  @Action({ rawError: true })
  public async fetchNearbyCourierList(params: Record<string, string | number>) {
    this.setNearbyCourierData([])
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getNearbyCourier(formattedParams)
      .then(res => {
        this.setNearbyCourierData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Nearby Courier Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async fetchPickupList(
    params: Record<string, string | number | boolean | string[]>
  ) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setPickupData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async fetchPickupCRRNFDList(
    params: Record<string, string | number | boolean | string[]>
  ) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getPickupCRRNFD(formattedParams)
      .then(res => {
        this.setPickupData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async fetchPickupCRRDONList(
    params: Record<string, string | number | boolean | string[]>
  ) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getPickupCRRDON(formattedParams)
      .then(res => {
        this.setPickupData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPickupDetail(id: string) {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setPickupDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Detail Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setPickupDetail(new PickupDetail())
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPickupDetails(params: Record<string, string>) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getBulk(formattedParams)
      .then(async bulkRes => {
        const lastCourierIds: Array<number> = []

        bulkRes.forEach(item => {
          const lastCancelData = item.histories?.find(
            history => history.statusId === 'CRRCNC'
          )

          lastCourierIds.push(lastCancelData?.actorId || NaN)
        })

        await Promise.allSettled(
          lastCourierIds.map(async (courierId, index) => {
            if (!courierId) return ''

            try {
              const courierRes = await this.manageCourierPresenter
                .getDetail(String(courierId))
                .then(res => {
                  return `[${res.courierId}] ${res.fullName} (${res.announcementCourierType}) ${res.phoneNumber} ${res.partnerName}`
                })
              return Promise.resolve(courierRes)
            } catch (e) {
              const error = e as {
                error: { message: { en: string; id: string } }
              }
              return Promise.reject({
                message: error.error.message.en,
              })
            }
          })
        ).then(response => {
          const resVal = response
            .filter(courier => courier.status === 'fulfilled')
            .map(courier => ((courier as unknown) as { value: string }).value)

          resVal.forEach((name, index) => {
            bulkRes[index].lastCancelCourier = name
          })
        })

        this.setPickupDetailBulk(bulkRes)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Detail Bulk Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPickupDetailForce(
    params: Record<string, string | number | boolean | string[]>
  ) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter
      .getPickupDetail(formattedParams)
      .then(res => {
        this.setPickupDetailForce(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Detail Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPickupSnapshotDetail(id: string) {
    this.setLoading(true)
    this.presenter
      .getSnapshot(id)
      .then(res => {
        this.setPickupSnapshotDetail(res)
      })
      .catch(error => {
        if (error.status === 404) {
          this.setPickupSnapshotDetail([])
        } else {
          Vue.notify({
            title: 'Fetch Pickup Snapshot Detail Failed',
            text:
              error.status === 400 || error.status === 422
                ? error.error.message.en
                : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
  }

  @Action({ rawError: true })
  public getPickupSummary(params?: Record<string, string>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter
      .getSummary(formattedParams)
      .then(res => {
        this.setPickupSummary(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Summary Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportPickup(form: { statusId: string; email: string }) {
    this.setExportLoading(true)

    this.presenter
      .export(new ExportPickupApiRequest(form.statusId, form.email))
      .then(urls => {
        urls.forEach(url => (window.location.href = url))
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Pickup Data Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setExportLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportCRRNFD(params: Record<string, string>) {
    this.setExportCRRNFDLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .exportCRRNFD(formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Pickup Data Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setExportCRRNFDLoading(false)
      })
  }

  @Action({ rawError: true })
  public async getPickupList(
    params: Record<string, string | number | string[] | boolean | undefined>
  ) {
    if (params.reset) this.setPickupData(undefined)
    delete params.reset

    this.setLatestSync(dayjs().toISOString())

    // If multiple status Ids
    if (params.statusIds) {
      const statusIdList: Array<string> = <string[]>params.statusIds

      statusIdList.forEach(async statusId => {
        await this.fetchPickupList({
          ...params,
          clear: true,
          statusId: statusId,
          statusIds: statusIdList,
        })
      })
    } else {
      if (params.statusId === 'CRRNFD') {
        await this.fetchPickupCRRNFDList({
          ...params,
          clear: false,
        })
      } else if (params.statusId === 'CRRDON') {
        await this.fetchPickupCRRDONList({
          ...params,
          clear: false,
        })
      } else {
        await this.fetchPickupList({
          ...params,
          clear: false,
        })
      }
    }
  }

  @Action({ rawError: true })
  public getCourierList(params: Record<string, string | number | undefined>) {
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.setCourierLoading(true)
    this.podTrackerPresenter
      .getDrivers(formattedParams)
      .then(res => {
        this.setCourierData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Courier Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setCourierLoading(false)
      })
  }

  @Action({ rawError: true })
  public manualAssignee(form: {
    shipmentIds: string[]
    courierId: number
    statusId: string
    manualAssignDate?: string
    timeZone?: string,
    plateNo?: string
  }) {
    
    const payload = new ManualAssigneeApiRequest({
      ids: form.shipmentIds,
      courierId: form.courierId,
      manualAssignDate: form.manualAssignDate,
      timeZone: form.timeZone,
      plateNo: form.plateNo
    })
 
    this.setLoading(true)

    const presenter: PickupPresenter = container.resolve(PickupPresenter)
    presenter
      .manualAssignee(payload)
      .then(() => {
        this.setRequestManualAssign(EventBusConstants.MANUAL_ASSIGNEE_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Manual Assignee Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public markAsDone(form: MarkAsDoneForm) {
    this.setLoading(true)

    const presenter: PickupPresenter = container.resolve(PickupPresenter)
    presenter
      .markAsDone(
        new MarkAsDoneApiRequest(
          form.groupId,
          form.shipmentId,
          form.courierId,
          form.quantity,
          form.hubPersonName,
          form.personName,
          form.relationId
        ),
        !!form.groupId
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.PICKUP_MARK_AS_DONE_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Mark As Done Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public cancelPickup(form: { shipmentId: string; note: string }) {
    this.setLoading(true)

    const presenter: PickupPresenter = container.resolve(PickupPresenter)
    presenter
      .cancelPickup(
        new CancelPickupApiRequest(form.shipmentId, 'ZZZZZ', form.note)
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.CANCEL_PICKUP_SUCCESS, true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Cancel Pickup Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public pickupDedicatedCRROTW(form: {
    taskId: number
    shipmentId: string
    actorId: number
    email: string
    from: string
  }) {
    this.setLoading(true)

    const presenter: PickupPresenter = container.resolve(PickupPresenter)
    presenter
      .pickupDedicatedCRROTW(
        new PickupDedicatedCRROTWRequest(
          form.taskId,
          form.shipmentId,
          form.actorId,
          form.email,
          form.from
        )
      )
      .then(() => {
        this.setIsSuccessForce(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Cancel Pickup Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async getPickupAdditionalTime(
    params: Record<string, string | number | boolean>
  ) {
    if (params.reset) {
      this.setPickupAdditionalTime(undefined)
    }

    delete params.reset

    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter
      .getPickupAdditionalTime(formattedParams)
      .then(res => {
        this.setPickupAdditionalTime(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Additional Time Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async exportPickupList(
    params: Record<string, string | number | boolean | string[] | undefined>
  ) {
    this.setExportPickupListLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .exportPickupList(formattedParams)
      .then(urls => {
        window.open(urls)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Pickup Data Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setExportPickupListLoading(false)
      })
  }

  @Action({ rawError: true })
  public async getPickupCRROTW(
    params: Record<string, string | number | boolean>
  ) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    if (params.syncSummary) {
      this.setLatestSync(new Date().toISOString())
    }

    this.presenter
      .getListCRROTW(formattedParams)
      .then(res => {
        this.setPickupCRROTW({
          payload: res,
          sync: <boolean>params.syncSummary,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup CRROTW Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async forceCRRCNC(params: { shipmentId: string; reason: string }) {
    this.setLoading(true)
    const courierId = this.pickupCRROTWData.data?.find(
      item => item.shipmentId === params.shipmentId
    )?.courierPickupDetail?.courierId

    const payload = new PickupForceCRRCNCRequest(
      params.shipmentId,
      <number>courierId,
      params.reason
    )

    const presenter: PickupPresenter = container.resolve(PickupPresenter)

    presenter
      .forceCRRCNC(payload)
      .then(() => {
        this.setIsSuccessForceCRRCNC(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Force CRRCNC Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsFailedForceCRRCNC(true)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async updateIsContactedCRROTW(
    params: Record<string, string | number | boolean>
  ) {
    this.setLoading(true)
    const payload = new UpdateIsContactedCRROTWRequest(<number>params.status)
    this.presenter
      .updateIsContactedCRROTW(<number>params.taskId, payload)
      .then(() => {
        this.successUpdateIsContactedCRROTW({
          bool: true,
          index: <number>params.index,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Update isContacted CRROTW Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async updateIsContactedCRRQUE(
    params: Record<string, string | number | boolean>
  ) {
    this.setLoading(true)
    const payload = new UpdateIsContactedCRRQUERequest(
      <boolean>params.isContacted
    )
    this.presenter
      .updateIsContactedCRRQUE(<string>params.shipmentId, payload)
      .then(() => {
        this.successUpdateIsContactedCRRQUE({
          bool: true,
          index: <number>params.index,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Update isContacted CRRQUE Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async exportCRROTW(params: Record<string, string | number | boolean>) {
    this.setExportCRROTWLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .exportCRROTW(formattedParams)
      .then(urls => {
        window.open(urls)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export CRROTW Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setExportCRROTWLoading(false)
      })
  }

  @Action({ rawError: true })
  public async forceCRRSRC(shipmentId: string): Promise<void> {
    this.setLoadingForceCRRSRC(true)
    const payload = new ForceCRRSRCRequest(shipmentId)

    const presenter: PickupPresenter = container.resolve(PickupPresenter)
    presenter
      .forceCRRSRC(payload)
      .then(() => {
        this.setIsForceCRRSRCSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Force CRRSRC Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingForceCRRSRC(false)
      })
  }

  @Action({ rawError: true })
  public async getPickupCRRQUE(
    params: Record<string, string | number | boolean>
  ) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getListCRRQUE(formattedParams)
      .then(res => {
        this.setLatestSync(new Date().toISOString())
        this.setPickupCRRQUE({
          data: res,
          sync: <boolean>params.summary,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup CRRQUE Failed',
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async forceCancelCRRQUE(params: {
    shipmentId: string
    note: string
    status: string
  }): Promise<void> {
    this.setLoading(true)

    const payload = new ForceCancelCRRQUERequest(params.shipmentId, params.note)

    const presenter: PickupPresenter = container.resolve(PickupPresenter)
    presenter
      .forceCancelCRRQUE(payload)
      .then(() => {
        this.setIsSuccessForceCancelCRRQUE(true)
      })
      .catch(error => {
        Vue.notify({
          title: `Force ${params.status} Failed`,
          text:
            error.status === 400 || error.status === 422 || error.status === 404
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsFailedForceCancelCRRQUE(true)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async exportCRRQUE(params: Record<string, string | number | boolean>) {
    this.setExportCRRQUELoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .exportCRRQUE(formattedParams)
      .then(urls => {
        window.open(urls)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export CRRQUE Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setExportCRRQUELoading(false)
      })
  }

  @Action({ rawError: true })
  public async exportManualAssignLog(params: Record<string, string>) {
    this.setLoadingExportManualAssign(true)
    this.setIsSuccessExportManualAssignLog(false)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .exportManualAssignLog(formattedParams)
      .then(urls => {
        this.setIsSuccessExportManualAssignLog(true)
        Vue.notify({
          title: 'Export Manual Assign Success',
          text: 'Manual Assign Log Export Successfully',
          type: 'success',
          duration: 5000,
        })
        window.open(urls)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Manual Assign Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingExportManualAssign(false)
      })
  }

  @Action({ rawError: true })
  public fetchPickupCorporateList(
    params: Record<string, string | number>
  ): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getListCorporate(formattedParams)
      .then(res => {
        this.setLatestSync(new Date().toISOString())
        this.setPickupCorporateData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Corporate List Failed',
          text: [400, 402].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async fetchCorporateSummary() {
    this.setLoading(true)
    this.presenter
      .getCorporateSummary()
      .then(data => {
        this.setPickupCorporateSummary(data)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Corporate Summary Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setNearbyCourierData(couriers: NearbyCourier[]) {
    this.nearbyCourierData = couriers
  }

  @Mutation
  private setPickupData(pickups: Pickups | undefined) {
    if (pickups) {
      this.paginationData = pickups.pagination
      this.pickupData = [...this.pickupData, ...pickups.data]
    } else {
      this.paginationData = new Pagination()
      this.pickupData = []
    }
  }

  @Mutation
  public setPickupDetail(pickup: PickupDetail) {
    this.pickupDetail = pickup
  }

  @Mutation
  private setPickupDetailBulk(pickup: PickupDetail[]) {
    this.pickupDetails = pickup
  }

  @Mutation
  private setPickupDetailForce(pickup: PickupForceDetail) {
    this.pickupDetailForce = pickup
  }

  @Mutation
  private setPickupSnapshotDetail(snapshot: PickupDetailSnapshot[]) {
    this.pickupSnapshotDetail = snapshot
  }

  @Mutation
  private setPickupSummary(pickup: PickupSummary) {
    this.pickupSummary = pickup
  }

  @Mutation
  private setCourierData(couriers: PODTrackerDrivers) {
    this.courierData = (couriers.data || []).map(courier => ({
      label: `[${courier.courierId}] ${courier.fullname} (${courier.announcementCourierType}) ${courier.phoneNumber} • ${courier.partnerName}`,
      value: courier.courierId as number,
    }))
  }

  @Mutation
  private setLatestSync(latestSync: string | null) {
    this.latestSync = latestSync
  }

  @Mutation
  private setExportLoading(bool: boolean) {
    this.isExportLoading = bool
  }

  @Mutation
  private setExportCRRNFDLoading(bool: boolean) {
    this.isExportCRRNFDLoading = bool
  }

  @Mutation
  private setExportCRROTWLoading(bool: boolean) {
    this.isExportCRROTWLoading = bool
  }

  @Mutation
  private setExportCRRQUELoading(bool: boolean) {
    this.isExportCRROTWLoading = bool
  }

  @Mutation
  private setCourierLoading(bool: boolean) {
    this.isCourierLoading = bool
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  public setIsSuccessForce(bool: boolean) {
    this.isSuccessForce = bool
  }

  @Mutation
  public setPickupBulkData(payload: PickupBulkDataState | null) {
    this.pickupBulkData = payload
  }

  @Mutation
  public setRequestManualAssign(message: string) {
    this.requestManualAssign = message
  }

  @Mutation
  public setPickupAdditionalTime(payload: PickupAdditionalTimes | undefined) {
    if (payload) {
      this.paginationData = payload.pagination
      this.pickupAdditionalTimeData = payload.data
    } else {
      this.paginationData = new Pagination()
      this.pickupAdditionalTimeData = []
    }
  }

  @Mutation
  private setExportPickupListLoading(bool: boolean) {
    this.isexportPickupListLoading = bool
  }

  @Mutation
  public setPickupCRROTW(payload: { payload: PickupCRROTWs; sync: boolean }) {
    this.pickupCRROTWData.data = payload.payload.data || []
    this.pickupCRROTWData.pagination = payload.payload.pagination
    if (payload.sync) {
      this.pickupCRROTWData.summary = payload.payload.summary
    }
  }

  @Mutation
  public setIsSuccessForceCRRCNC(bool: boolean) {
    this.isSuccessForceCRRCNC = bool
  }

  @Mutation
  public setIsFailedForceCRRCNC(bool: boolean) {
    this.isFailedForceCRRCNC = bool
  }

  @Mutation
  public successUpdateIsContactedCRROTW(payload: {
    bool: boolean
    index: number
  }) {
    if (payload.bool) {
      if (this.pickupCRROTWData.data?.length) {
        this.pickupCRROTWData.data[payload.index].isContacted = !this
          .pickupCRROTWData.data[payload.index].isContacted
      }
    }
  }

  @Mutation
  public successUpdateIsContactedCRRQUE(payload: {
    bool: boolean
    index: number
  }) {
    if (payload.bool) {
      if (this.pickupCRRQUEData.data?.length) {
        this.pickupCRRQUEData.data[payload.index].isContacted = !this
          .pickupCRRQUEData.data[payload.index].isContacted
      }
    }
  }

  @Mutation
  public setIsForceCRRSRCSuccess(bool: boolean): void {
    this.isForceCRRSRCSuccess = bool
  }

  @Mutation
  public setLoadingForceCRRSRC(bool: boolean): void {
    this.isLoadingForceCRRSRC = bool
  }

  @Mutation
  private setPickupCRRQUE(payload: {
    data: PickupCRRQUEs
    sync: boolean
  }): void {
    this.pickupCRRQUEData.data = payload.data.data
    this.pickupCRRQUEData.pagination = payload.data.pagination
    if (payload.sync) {
      this.pickupCRRQUEData.summary = payload.data.summary
    }
  }

  @Mutation
  public setIsSuccessForceCancelCRRQUE(bool: boolean): void {
    this.isSuccessForceCancelCRRQUE = bool
  }

  @Mutation
  public setIsFailedForceCancelCRRQUE(bool: boolean): void {
    this.isFailedForceCancelCRRQUE = bool
  }

  @Mutation
  private setLoadingExportManualAssign(bool: boolean): void {
    this.isLoadingExportManualAssignLog = bool
  }

  @Mutation
  public setIsSuccessExportManualAssignLog(bool: boolean): void {
    this.isSuccessExportManualAssignLog = bool
  }

  @Mutation
  public setPickupCorporateData(data: PickupCorporates): void {
    this.pickupCorporateData = data.data
    this.paginationData = data.pagination
  }

  @Mutation
  public setPickupCorporateSummary(data: PickupCorporateSummary): void {
    this.pickupCorporateSummary = data
  }
}

export default getModule(PickupController)
